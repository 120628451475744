import {notification} from "antd";

const Notification = (type, message, description) => {

    notification[type]({
        message,
        description,
        placement: "bottomLeft",
        style: {
            marginBottom: "100px",
        },
    });
};

export default Notification;