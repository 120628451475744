import {PMS_PURCHASE2_PATH, PMS_PURCHASE3_PATH, PMS_PURCHASE4_PATH, PMS_PURCHASE_PATH} from "../routes/Slugs";

const version = "2.0.0";
const pms_version = "1.0.0";

//OS
const {REACT_APP_OS_BASE_URL} = process.env;
const {REACT_APP_IMAGE_OS_URL} = process.env;

//PMS
const {REACT_APP_PMS_BASE_URL} = process.env;
const {REACT_APP_IMAGE_PMS_URL} = process.env;

// OS URL
export const ROOT_URL = `${REACT_APP_OS_BASE_URL}/api/v/${version}`;
export const IMAGE_URL = `${REACT_APP_IMAGE_OS_URL}/api/v/${version}`;

//PMS URL
export const PMS_ROOT_URL = `${REACT_APP_PMS_BASE_URL}/api/v/${pms_version}`;
export const PMS_IMAGE_URL = `${REACT_APP_IMAGE_PMS_URL}/api/v/${pms_version}`;

export const PUBLIC_API_URL = `${ROOT_URL}/public`;
export const SECURED_API_URL = `${ROOT_URL}/secured`;
export const PMS_SECURED_API_URL = `${PMS_ROOT_URL}/secured`;

// image
export const IMAGE_UPLOAD = `${SECURED_API_URL}/image/upload`;

//user
export const SIGN_IN = `${PUBLIC_API_URL}/users/login`;
export const ADMIN_SIGN_IN = `${PUBLIC_API_URL}/admin/login`;
export const PHARMACIST_SIGN_IN = `${PUBLIC_API_URL}/pharmacists/login`;

export const GET_USER_PROFILE = `${SECURED_API_URL}/users/profile`;

//dashboard
export const GET_ALL_ACTIVE_PHARMACIES = `${SECURED_API_URL}/dashboards/active-pharmacies`;
export const GET_ACTIVE_PHARMACY_COUNT = `${SECURED_API_URL}/dashboards/pharmacies/active/count`;
export const GET_ALL_CUSTOMERS_COUNT = `${SECURED_API_URL}/dashboards/customers/total/count`;
export const GET_ALL_PENDING_ORDER_COUNT = `${SECURED_API_URL}/dashboards/orders/pending/count`;
export const GET_ALL_DELIVERY_PERSONS_COUNT = `${SECURED_API_URL}/dashboards/delivery-persons/active/count`;

//picture upload
export const PROFILE_PICTURE_UPLOAD_PATH = `${SECURED_API_URL}/profile-pic/upload`;
export const NID_FRONT_PICTURE_UPLOAD_PATH = `${SECURED_API_URL}/nid-front-pic/upload`;
export const NID_BACK_PICTURE_UPLOAD_PATH = `${SECURED_API_URL}/nid-back-pic/upload`;

export const PRODUCT_PICTURE_UPLOAD = `${SECURED_API_URL}/product-pic/upload`;

//pharmacy logo upload
export const PHARMACY_PIC_UPLOAD_URL = `${SECURED_API_URL}/pharmacy-pic/upload`;

//item picture upload
export const ITEM_PICTURE_UPLOAD_URL = `${SECURED_API_URL}/item-pic/upload`;

//user
export const GET_ALL_USERS = `${SECURED_API_URL}/users`;
export const GET_USER_BY_ID = `${SECURED_API_URL}/users/id`;
export const POST_USER = `${SECURED_API_URL}/users/create`;
export const UPDATE_USER = `${SECURED_API_URL}/users/update`;
export const UPDATE_PROFILE = `${SECURED_API_URL}/users/profile`;
export const DELETE_USER = `${SECURED_API_URL}/users/delete`;
export const CHANGE_ROLE = `${SECURED_API_URL}/users/change-role`;
export const USER_IMAGE_UPLOAD = `${SECURED_API_URL}/user-image/upload`;

//role
export const GET_ALL_ROLES = `${SECURED_API_URL}/users/roles`;
export const POST_ROLE = `${SECURED_API_URL}/users/create-role`;
export const UPDATE_ROLE = `${SECURED_API_URL}/users/update-role`;
export const DELETE_ROLE = `${SECURED_API_URL}/users/delete-role`;
export const GET_ROLE_BY_ID = `${SECURED_API_URL}/users/roles/id`;

//permissions
export const GET_ALL_PERMISSIONS = `${SECURED_API_URL}/users/permissions`;
export const GET_PERMISSIONS_BY_USER_TYPE = `${SECURED_API_URL}/users/permissions/user-type`;

//pharmacy
export const GET_ALL_PHARMACIES = `${SECURED_API_URL}/pharmacies`;
export const GET_PHARMACY_BY_ID = `${SECURED_API_URL}/pharmacies/id`;
export const POST_PHARMACY = `${SECURED_API_URL}/pharmacies/create`;
export const UPDATE_PHARMACY = `${SECURED_API_URL}/pharmacies/update`;
export const CHECK_PHARMACY_AS_ONLINE = `${SECURED_API_URL}/pharmacies/os/check-as-online`;
export const DELETE_PHARMACY = `${SECURED_API_URL}/users/permissions`;
export const GET_SUBSCRIPTION_PLAN_FOR_PHARMACY = `${SECURED_API_URL}/pharmacies/subscription-plan`;
export const GET_PHARMACY_ROLES_BY_ID = `${SECURED_API_URL}/users/roles/pharmacy`;
export const INIT_PHARMACY_DEFAULT_PRODUCT = `${SECURED_API_URL}/pharmacies/init/pms-default-data`;
export const INIT_PHARMACY_INVENTORY_FILE = `${PMS_SECURED_API_URL}/init/inventory/by/files`;
export const GET_PHARMACY_STATISTICS = `${PMS_SECURED_API_URL}/reports/latest-date-time-list`;

//profit sharing plan
export const GET_ALL_PROFIT_SHARING_PLANS = `${SECURED_API_URL}/profit-sharing-plans`;
export const GET_PROFIT_SHARING_PLAN_BY_ID = `${SECURED_API_URL}/profit-sharing-plans/id`;
export const POST_PROFIT_SHARING_PLAN = `${SECURED_API_URL}/profit-sharing-plans/create`;
export const UPDATE_PROFIT_SHARING_PLAN = `${SECURED_API_URL}/profit-sharing-plans/update`;
export const DELETE_PROFIT_SHARING_PLAN = `${SECURED_API_URL}/profit-sharing-plans/delete`;

//company
export const GET_ALL_COMPANIES = `${SECURED_API_URL}/companies`;
export const GET_COMPANY_BY_ID = `${SECURED_API_URL}/companies/id`;
export const UPDATE_COMPANY = `${SECURED_API_URL}/companies/update`;
export const POST_COMPANY = `${SECURED_API_URL}/companies/create`;
export const DELETE_COMPANY = `${SECURED_API_URL}/companies/delete`;

//country
export const GET_ALL_COUNTRIES = `${SECURED_API_URL}/countries`;
export const GET_COUNTRY_BY_ID = `${SECURED_API_URL}/countries/id`;
export const UPDATE_COUNTRY = `${SECURED_API_URL}/countries/update`;
export const POST_COUNTRY = `${SECURED_API_URL}/countries/create`;
export const DELETE_COUNTRY = `${SECURED_API_URL}/country/delete`;

//thana
export const GET_ALL_THANAS = `${SECURED_API_URL}/thanas`;
export const GET_THANA_BY_ID = `${SECURED_API_URL}/thanas/id`;
export const POST_THANA = `${SECURED_API_URL}/thanas/create`;
export const UPDATE_THANA = `${SECURED_API_URL}/thanas/update`;
export const DELETE_THANA = `${SECURED_API_URL}/thanas/delete`;

//thana
export const GET_ALL_DISTRICS = `${SECURED_API_URL}/districts`;
export const GET_DISTRIC_BY_ID = `${SECURED_API_URL}/districts/id`;
export const POST_DISTRIC = `${SECURED_API_URL}/districts/create`;
export const UPDATE_DISTRIC = `${SECURED_API_URL}/districts/update`;
export const DELETE_DISTRIC = `${SECURED_API_URL}/districts/delete`;

//item
export const GET_ALL_ITEMS = `${SECURED_API_URL}/items`;
export const POST_ITEM = `${SECURED_API_URL}/items/create`;
export const UPDATE_ITEM = `${SECURED_API_URL}/items/update`;
export const DELETE_ITEM = `${SECURED_API_URL}/items/delete`;

//drug
export const GET_ALL_DRUGS = `${SECURED_API_URL}/drugs`;
export const POST_DRUG = `${SECURED_API_URL}/drugs/create`;
export const UPDATE_DRUG = `${SECURED_API_URL}/drugs/update`;
export const DELETE_DRUG = `${SECURED_API_URL}/drugs/delete`;

//generic
export const GET_ALL_GENERICS = `${SECURED_API_URL}/generics`;
export const GET_GENERIC_BY_ID = `${SECURED_API_URL}/generics/id`;
export const POST_GENERIC = `${SECURED_API_URL}/generics/create`;
export const UPDATE_GENERIC = `${SECURED_API_URL}/generics/update`;
export const DELETE_GENERIC = `${SECURED_API_URL}/generics/delete`;

//Product Type
export const GET_ALL_PRODUCT_TYPES = `${SECURED_API_URL}/product-types`;
export const GET_PRODUCT_TYPE_BY_ID = `${SECURED_API_URL}/product-types/id`;
export const POST_PRODUCT_TYPE = `${SECURED_API_URL}/product-types/create`;
export const UPDATE_PRODUCT_TYPE = `${SECURED_API_URL}/product-types/update`;
export const DELETE_PRODUCT_TYPE = `${SECURED_API_URL}/product-types/delete`;

//product
export const GET_ALL_PRODUCTS = `${SECURED_API_URL}/products`;
export const GET_ALL_FEATURED_PRODUCTS = `${SECURED_API_URL}/products/featured/lite`;
export const POST_PRODUCT = `${SECURED_API_URL}/products/create`;
export const GET_ALL_PENDING_PRODUCTS = `${SECURED_API_URL}/products/pending`;
export const GET_PRODUCT_BY_ID = `${SECURED_API_URL}/products/id`;
export const UPDATE_PRODUCT = `${SECURED_API_URL}/products/update`;
export const VERIFY_PRODUCT = `${SECURED_API_URL}/products/update/as-verified`;
export const UPDATE_PRODUCT_AS_POPULAR = `${SECURED_API_URL}/products/update/as-popular`;
export const DELETE_PRODUCT = `${SECURED_API_URL}/products/delete`;

export const GET_GENERICS_URL = `${SECURED_API_URL}/generics`;

//product category
export const GET_ALL_PRODUCT_CATEGORIES = `${SECURED_API_URL}/categories`;
export const GET_PRODUCT_CATEGORY_BY_ID = `${SECURED_API_URL}/categories/id`;
export const POST_PRODUCT_CATEGORY = `${SECURED_API_URL}/categories/create`;
export const UPDATE_PRODUCT_CATEGORY = `${SECURED_API_URL}/categories/update`;
export const DELETE_PRODUCT_CATEGORY = `${SECURED_API_URL}/categories/delete`;
export const SET_AS_HOMEPAGE = `${SECURED_API_URL}/categories/set-as-homepage`;
export const SET_AS_HOT = `${SECURED_API_URL}/categories/set-as-hot`;

//price ledger
export const GET_ALL_PRICE_LEDGERS = `${SECURED_API_URL}/priceL-ledgers`;
export const POST_PRICE_LEDGER = `${SECURED_API_URL}/priceL-ledgers/create`;
export const UPDATE_PRICE_LEDGER = `${SECURED_API_URL}/priceL-ledgers/update`;
export const DELETE_PRICE_LEDGER = `${SECURED_API_URL}/priceL-ledgers/delete`;

//product listing
export const GET_ALL_PRODUCT_LISTINGS = `${SECURED_API_URL}/product-listings`;
export const POST_PRODUCT_LISTING = `${SECURED_API_URL}/product-listings/create`;
export const UPDATE_PRODUCT_LISTING = `${SECURED_API_URL}/product-listings/update`;
export const DELETE_PRODUCT_LISTING = `${SECURED_API_URL}/product-listings/delete`;

//prescription
export const GET_ALL_PRESCRIPTIONS = `${SECURED_API_URL}/prescriptions`;
export const GET_PRESCRIPTION_BY_ID = `${SECURED_API_URL}/prescriptions/id`;
export const POST_PRESCRIPTION = `${SECURED_API_URL}/prescriptions/create`;
export const UPDATE_PRESCRIPTION = `${SECURED_API_URL}/prescriptions/digitalized`;
export const DELETE_PRESCRIPTION = `${SECURED_API_URL}/prescriptions/delete`;

//hospital
export const GET_ALL_HOSPITALS = `${SECURED_API_URL}/hospitals`;

//order
export const GET_ALL_ORDERS = `${SECURED_API_URL}/orders`;
export const GET_ORDER_BY_ID = `${SECURED_API_URL}/orders/id`;
export const GET_ORDER_TRACKING_BY_ID = `${SECURED_API_URL}/orders/state`;
export const UPDATE_ORDER = `${SECURED_API_URL}/orders/update`;
export const POST_ORDER = `${SECURED_API_URL}/orders/create`;
export const DELETE_ORDER = `${SECURED_API_URL}/orders/delete`;
export const GET_ALL_STACKED_ORDERS = `${SECURED_API_URL}/orders/verification-order-stack`;
export const PROCESS_ORDER = `${SECURED_API_URL}/orders/process-order-verification`;
export const PROCESS_ORDER_CALCULATION = `${SECURED_API_URL}/orders/process-order-verification-calculation`;
export const GET_ALL_PENDING_ORDERS_FOR_DELIVERY_PERSONS = `${SECURED_API_URL}/orders/pending-order-for-delivery-person`;
export const ASSIGN_ORDER_TO_DELIVERY_PERSON = `${SECURED_API_URL}/orders/assign-order-to-delivery-person`;
export const CANCEL_ORDER = `${SECURED_API_URL}/orders/cancel/id`;
export const DEFAULT_DELIVERY_PERSON_ORDER_STACK = `${SECURED_API_URL}/orders/default-delivery-person-order-stack`;
export const DEFAULT_PHARMACY_ORDER_STACK = `${SECURED_API_URL}/orders/default-pharmacy-order-stack`;

//pharmacy owner
export const GET_ALL_PHARMACY_OWNERS = `${GET_ALL_USERS}?userType=PHARMACY_OWNER`;
export const POST_PHARMACY_OWNER = `${SECURED_API_URL}/pharmacy-owners/create`;
export const UPDATE_PHARMACY_OWNER = `${SECURED_API_URL}/pharmacy-owners/update`;
export const DELETE_PHARMACY_OWNER = `${SECURED_API_URL}/pharmacy-owners/delete`;
export const POST_PHARMACIST_FROM_PHARMACY_OWNER = `${SECURED_API_URL}/pharmacy-owners/pharmacist/create`;
export const UPDATE_PHARMACIST_FROM_PHARMACY_OWNER = `${SECURED_API_URL}/pharmacy-owners/pharmacist/update`;
export const POST_PRODUCT_REQUEST = `${SECURED_API_URL}/pharmacy-owners/product/request`;
export const GET_ALL_PRODUCTS_FOR_PHARMACY_OWNER = `${SECURED_API_URL}/pharmacy-owners/product/all`;
export const GET_ALL_PHARMACY_FOR_PHARMACY_OWNER = `${SECURED_API_URL}/pharmacy-owners/pharmacies/lite`;

//pharmacist
export const GET_ALL_PHARMACISTS = `${SECURED_API_URL}/pharmacists`;
export const GET_PHARMACIST_BY_ID = `${SECURED_API_URL}/pharmacists/id`;
export const POST_PHARMACIST_FROM_ADMIN = `${SECURED_API_URL}/pharmacists/create`;
export const UPDATE_PHARMACIST_FROM_ADMIN = `${SECURED_API_URL}/pharmacists/update`;
export const UPDATE_PHARMACIST = `${SECURED_API_URL}/pharmacists/update`;
export const DELETE_PHARMACIST = `${SECURED_API_URL}/pharmacists/delete`;
export const GET_PHARMACIST_PROFILE = `${SECURED_API_URL}/pharmacists-profile`;

//delivery persons
export const GET_ALL_DELEVERY_PERSONS = `${SECURED_API_URL}/delivery-persons`;
export const POST_DELEVERY_PERSON = `${SECURED_API_URL}/delivery-persons/create`;
export const UPDATE_DELEVERY_PERSON = `${SECURED_API_URL}/delivery-persons/update`;
export const GET_DELEVERY_PERSON_BY_ID = `${SECURED_API_URL}/delivery-persons/id`;
export const DELETE_DELEVERY_PERSON = `${SECURED_API_URL}/delivery-persons/delete`;
export const DELIVERY_PERSON_STATUS = `${SECURED_API_URL}/delivery-persons/os/check-as-online`;

export const API_URL = `${ROOT_URL}/api/${version}`;

export const USER_ROOT_URL = "http://173.82.104.14:8081";
export const USER_API_URL = `${USER_ROOT_URL}/api/${version}`;

export const ORDER_ROOT_URL = "http://173.82.104.14:8083";
export const ORDER_API_URL = `${ORDER_ROOT_URL}/api/${version}`;

//Customers
export const GET_ALL_CUSTOMERS = `${SECURED_API_URL}/customers`;
export const GET_CUSTOMER_BY_ID = `${SECURED_API_URL}/customers/id`;
export const POST_CUSTOMER = `${SECURED_API_URL}/customers/create`;
export const UPDATE_CUSTOMER = `${SECURED_API_URL}/customers/update`;
export const DELETE_CUSTOMER = `${SECURED_API_URL}/customers/delete`;

//Customers
export const GET_ALL_CUSTOMER_GROUPS = `${SECURED_API_URL}/customer-groups`;
export const GET_CUSTOMER_GROUP_BY_ID = `${SECURED_API_URL}/customer-groups/id`;
export const POST_CUSTOMER_GROUP = `${SECURED_API_URL}/customer-groups/create`;
export const UPDATE_CUSTOMER_GROUP = `${SECURED_API_URL}/customer-groups/update`;
export const DELETE_CUSTOMER_GROUP = `${SECURED_API_URL}/customer-groups/delete`;

//commission plan
export const GET_ALL_COMMISSION_PLANS = `${SECURED_API_URL}/commission-plans`;
export const GET_COMMISSION_PLAN_BY_ID = `${SECURED_API_URL}/commission-plans/id`;
export const POST_COMMISSION_PLAN = `${SECURED_API_URL}/commission-plans/create`;
export const UPDATE_COMMISSION_PLAN = `${SECURED_API_URL}/commission-plans/update`;
export const DELETE_COMMISSION_PLAN = `${SECURED_API_URL}/commission-plans/delete`;

//promo
export const GET_ALL_PROMOS = `${SECURED_API_URL}/promos`;
export const GET_PROMO_BY_ID = `${SECURED_API_URL}/promos/id`;
export const POST_PROMO = `${SECURED_API_URL}/promos/create`;
export const UPDATE_PROMO = `${SECURED_API_URL}/promos/update`;
export const DELETE_PROMO = `${SECURED_API_URL}/promos/delete`;

//subscription plan
export const GET_ALL_SUBSCRIPTION_PLANS = `${SECURED_API_URL}/subscription-plans`;
export const GET_SUBSCRIPTION_PLAN_BY_ID = `${SECURED_API_URL}/subscription-plans/id`;
export const GET_SUBSCRIPTION_PLAN_BY_NAME = `${SECURED_API_URL}/subscription-plans/name`;
export const POST_SUBSCRIPTION_PLAN = `${SECURED_API_URL}/subscription-plans/create`;
export const UPDATE_SUBSCRIPTION_PLAN = `${SECURED_API_URL}/subscription-plans/update`;
export const DELETE_SUBSCRIPTION_PLAN = `${SECURED_API_URL}/subscription-plans/delete`;

//carts
export const GET_ALL_CARTS_URL = `${ORDER_API_URL}/carts`;
export const POST_CART_URL = `${ORDER_API_URL}/carts/add`;
export const UPDATE_CART_URL = `${ORDER_API_URL}/carts/update`;
export const GET_CART_BY_ID_URL = `${API_URL}/carts/id`;

//notification
export const GET_ALL_NOTIFICATIONS = `${SECURED_API_URL}/notifications`;
export const POST_NOTIFICATION = `${SECURED_API_URL}/notifications/create`;
export const UPDATE_NOTIFICATION = `${SECURED_API_URL}/push-notifications/update`;
export const GET_NOTIFICATION_BY_ID = `${SECURED_API_URL}/notifications/id`;
export const NOTIFICATION_IMAGE_UPLOAD = `${SECURED_API_URL}/notification-pic/upload`;

//tables
export const GET_ALL_TABLES = `${SECURED_API_URL}/ui-configs/table/all`;
export const GET_COLUMN_CONFIGS = `${SECURED_API_URL}/ui-configs/table/columns`;
export const POST_TABLE = `${SECURED_API_URL}/ui-configs/table/create`;
export const UPDATE_TABLE = `${SECURED_API_URL}/ui-configs/table/update`;
export const UPDATE_TABLE_COLUMN = `${SECURED_API_URL}/ui-configs/table/column/update`;
export const UPDATE_ALL_TABLE_COLUMNS = `${SECURED_API_URL}/ui-configs/table/columns/update`;
export const POST_TABLE_COLUMN = `${SECURED_API_URL}/ui-configs/table/column/create`;
export const DELETE_TABLE = `${SECURED_API_URL}/ui-configs/table/delete`;
export const GET_TABLE_BY_ID = `${SECURED_API_URL}/tables/id`;

//delivery charge controller
export const GET_ALL_DELIVERY_CHARGES = `${SECURED_API_URL}/delivery-charges`;
export const POST_DELIVERY_CHARGE = `${SECURED_API_URL}/delivery-charges/create`;
export const GET_DELIVERY_CHARGE_BY_ID = `${SECURED_API_URL}/delivery-charges/id`;
export const UPDATE_DELIVERY_CHARGE = `${SECURED_API_URL}/delivery-charges/update`;
export const DELETE_DELIVERY_CHARGE = `${SECURED_API_URL}/delivery-charges/delete`;

//pharmacy order
export const GET_ALL_PHARMACY_ORDER_ACCOUNTS = `${SECURED_API_URL}/pharmacy-order-accounts`;
export const GET_ALL_PHARMACY_ORDER_ACCOUNTS_BY_PHARMACY_ID = `${SECURED_API_URL}/pharmacy-order-accounts/pharmacy`;
export const GET_PHARMACY_ORDER_ACCOUNT_BY_ID = `${SECURED_API_URL}/pharmacy-order-accounts/id`;

//area
export const GET_ALL_AREAS = `${SECURED_API_URL}/areas`;
export const GET_AREA_BY_ID = `${SECURED_API_URL}/areas/id`;
export const POST_AREA = `${SECURED_API_URL}/areas/create`;
export const UPDATE_AREA = `${SECURED_API_URL}/areas/update`;
export const DELETE_AREA = `${SECURED_API_URL}/areas/delete`;

//event
export const GET_ALL_EVENTS = `${SECURED_API_URL}/events`;
export const POST_EVENT = `${SECURED_API_URL}/events/create`;
export const GET_EVENT_BY_ID = `${SECURED_API_URL}/events/id`;
export const UPDATE_EVENT = `${SECURED_API_URL}/events/update`;
export const DELETE_EVENT = `${SECURED_API_URL}/events/delete`;

//tag
export const GET_ALL_TAGS = `${SECURED_API_URL}/tags`;
export const POST_TAG = `${SECURED_API_URL}/tags/create`;
export const GET_TAG_BY_ID = `${SECURED_API_URL}/tags`;
export const UPDATE_TAG = `${SECURED_API_URL}/tags/update`;
export const DELETE_TAG = `${SECURED_API_URL}/tags/delete`;

//offers
export const GET_ALL_OFFERS = `${SECURED_API_URL}/offers`;
export const POST_OFFER = `${SECURED_API_URL}/offers/create`;
export const GET_OFFER_BY_ID = `${SECURED_API_URL}/offers/id`;

//offer details
export const GET_ALL_OFFER_DETAILS = `${SECURED_API_URL}/offer-descriptions`;
export const ADD_OFFER_DETAILS = `${SECURED_API_URL}/offer-descriptions/create`;
export const GET_OFFER_DETAILS_BY_ID = `${SECURED_API_URL}/offer-descriptions/id`;
export const UPDATE_OFFER_DETAILS = `${SECURED_API_URL}/offer-descriptions/update`;
export const DELETE_OFFER_DETAILS = `${SECURED_API_URL}/offer-descriptions/delete`;

export const UPLOAD_OFFER_DETAILS_PICTURE = `${SECURED_API_URL}/offer-pic/upload`;

//referral history
export const REFERRAL_HISTORY_LIST = `${SECURED_API_URL}/referral-history`;

//home page product
export const GET_HOMEPAGE_PRODUCT_PRODUCTS = `${SECURED_API_URL}/homepage-products`;
export const GET_HOMEPAGE_PRODUCT_BY_ID = `${SECURED_API_URL}/homepage-products/id`;
export const CREATE_HOMEPAGE_PRODUCT = `${SECURED_API_URL}/homepage-products/create`;
export const UPDATE_HOMEPAGE_PRODUCT = `${SECURED_API_URL}/homepage-products/update`;

//banner
export const GET_BANNER_LIST = `${SECURED_API_URL}/banners`;
export const GET_PHARMACY_BANNER_LIST = `${SECURED_API_URL}/banners/for-pharmacy-banner`;
export const GET_BANNER_BY_ID = `${SECURED_API_URL}/banners/id`;
export const ADD_BANNER = `${SECURED_API_URL}/banners/create`;
export const EDIT_BANNER = `${SECURED_API_URL}/banners/update`;
export const DELETE_BANNER = `${SECURED_API_URL}/banners/delete`;

export const BANNER_PIC_UPLOAD_URL = `${SECURED_API_URL}/banner-pic/upload`;

//invoices
export const GET_ALL_INVOICES = `${SECURED_API_URL}/invoices`;
export const GET_INVOICE_BY_ID = `${SECURED_API_URL}/invoices/id`;

//bill
export const GET_ALL_BILLS = `${SECURED_API_URL}/bills`;
export const GET_BILL_BY_ID = `${SECURED_API_URL}/bills/id`;

//review
export const GET_ALL_REVIEWS = `${SECURED_API_URL}/reviews`;
export const GET_REVIEW_BY_ID = `${SECURED_API_URL}/reviews/id`;

//global config
export const GET_ALL_GLOBAL_CONFIGS = `${SECURED_API_URL}/global-configs`;
export const GET_ALL_ACTIVE_GLOBAL_CONFIGS = `${SECURED_API_URL}/global-configs/active`;
export const POST_GLOBAL_CONFIG = `${SECURED_API_URL}/global-configs/create`;
export const UPDATE_GLOBAL_CONFIG = `${SECURED_API_URL}/global-configs/update`;
export const GET_GLOBAL_CONFIG_BY_ID = `${SECURED_API_URL}/global-configs/id`;
export const DELETE_GLOBAL_CONFIG = `${SECURED_API_URL}/global-configs/delete`;

// Pregnancy Category
export const PREGNANCY_CATEGORY = `${SECURED_API_URL}/pregnancy-categories`;

// TEMPORARY ORDERS
export const GET_ALL_TEMPORARY_ORDERS = `${SECURED_API_URL}/temporary-orders`;
export const GET_TEMPORARY_ORDER_BY_ID = `${SECURED_API_URL}/temporary-orders/id`;
export const CREATE_TEMPORARY_ORDER = `${SECURED_API_URL}/temporary-orders/create`;
export const UPDATE_TEMPORARY_ORDER_BY_ID = `${SECURED_API_URL}/temporary-orders/update`;
export const DELETE_TEMPORARY_ORDER_BY_ID = `${SECURED_API_URL}/temporary-orders/delete`;

// ORDER ROUTING SCHEDULE
export const GET_ALL_ORDER_ROUTING_SCHEDULE = `${SECURED_API_URL}/order-routing-schedules`;

// delivery_plans
export const GET_ALL_DELIVERY_PLANS = `${SECURED_API_URL}/delivery-plans`;
export const GET_DELIVERY_PLAN_BY_ID = `${SECURED_API_URL}/delivery-plans/id`;
export const CREATE_DELIVERY_PLAN = `${SECURED_API_URL}/delivery-plans/create`;
export const UPDATE_DELIVERY_PLAN_BY_ID = `${SECURED_API_URL}/delivery-plans/update`;
export const DELETE_DELIVERY_PLAN_BY_ID = `${SECURED_API_URL}/delivery-plans/delete`;

// Change Password
export const CHANGE_LOGGED_USER_PASSWORD = `${SECURED_API_URL}/users/change-password`;
export const CHANGE_ANOTHER_USER_PASSWORD_BY_ID = `${SECURED_API_URL}/users/change-password`;

//Lab test
export const GET_ALL_LAB_TEST = `${SECURED_API_URL}/lab-tests`;
export const CREATE_LAB_TEST = `${SECURED_API_URL}/lab-tests/create`;
export const UPDATE_LAB_TEST_BY_TEST_ID = `${SECURED_API_URL}/lab-tests/update`;
export const CREATE_LAB_TEST_PARAMETER = `${SECURED_API_URL}/lab-test-parameters/create`;
export const UPDATE_LAB_TEST_PARAMETER_PARAMETER_ID = `${SECURED_API_URL}/lab-test-parameters/update`;

export const GET_ALL_CUSTOMER_LAB_TEST = `${SECURED_API_URL}/customer-lab-tests`;
export const CREATE_CUSTOMER_LAB_TEST = `${SECURED_API_URL}/customer-lab-tests/create/all`;
export const UPDATE_CUSTOMER_LAB_TEST = `${SECURED_API_URL}/customer-lab-tests/update`;

export const UPLOAD_PRODUCT_FILES = `${SECURED_API_URL}/products/update/by/files`;

// --------------------- pms Start ---------------------------

// --------------------- pms Customer ------------------------
export const PMS_GET_ALL_CUSTOMER_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/customers`;
export const PMS_GET_CUSTOMER_BY_PHARMACY_ID_BY_ID = `${PMS_SECURED_API_URL}/customers`;
export const PMS_GET_CUSTOMER_BY_PHARMACY_ID_BY_LOCAL_ID = `${PMS_SECURED_API_URL}/customers/by/local-id`;
export const PMS_CREATE_CUSTOMER_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/customers/create`;
export const PMS_DELETE_CUSTOMER_BY_ID = `${PMS_SECURED_API_URL}/customers/delete`;
export const PMS_GET_ALL_LITE_CUSTOMER_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/customers/lite`;
export const PMS_CUSTOMER_QUEUE_SYNC = `${PMS_SECURED_API_URL}/customers/queue-sync`;
export const PMS_CUSTOMER_QUEUE_SYNC_ACKNOWLEDGEMENT = `${PMS_SECURED_API_URL}/customers/queue-sync/acknowledgement`;
export const PMS_CUSTOMER_SYNC_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/customers/sync`;
export const PMS_UPDATE_CUSTOMER_BY_ID_PHARMACY_ID = `${PMS_SECURED_API_URL}/customers/update`;

// --------------------- pms Expense ------------------------
export const PMS_GET_ALL_EXPENSE_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/expenses`;
export const PMS_GET_EXPENSE_BY_PHARMACY_ID_BY_ID = `${PMS_SECURED_API_URL}/expenses`;
export const PMS_CREATE_EXPENSE_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/expenses/create`;
export const PMS_EXPENSE_QUEUE_SYNC = `${PMS_SECURED_API_URL}/expenses/queue-sync`;
export const PMS_EXPENSE_QUEUE_SYNC_ACKNOWLEDGEMENT = `${PMS_SECURED_API_URL}/expenses/queue-sync/acknowledgement`;
export const PMS_EXPENSE_SYNC_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/expenses/sync`;
export const PMS_UPDATE_EXPENSE_BY_ID_PHARMACY_ID = `${PMS_SECURED_API_URL}/expenses/update`;

// --------------------- pms Inventory ------------------------
export const PMS_GET_ALL_INVENTORIES_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/inventories`;
export const PMS_GET_INVENTORY_BY_ID = `${PMS_SECURED_API_URL}/inventories`;
export const PMS_CREATE_INVENTORY_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/inventories/create`;
export const PMS_INVENTORY_SYNC_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/inventories/sync`;
export const PMS_UPDATE_INVENTORY_BY_ID_PHARMACY_ID = `${PMS_SECURED_API_URL}/inventories/update`;
export const PMS_GET_ALL_INVENTORY_VENDOR_BY_ID_PHARMACY_ID = `${PMS_SECURED_API_URL}/inventories/vendor`;

// --------------------- pms Payment ------------------------
export const PMS_GET_ALL_CUSTOMER_PAYMENT_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/payments/customer`;
export const PMS_CANCEL_CUSTOMER_PAYMENT_BY_ID_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/payments/customers/cancel`;
export const PMS_CREATE_CUSTOMER_PAYMENT_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/payments/customers/create`;
export const PMS_CUSTOMER_PAYMENT_QUEUE_SYNC = `${PMS_SECURED_API_URL}/payments/customers/queue-sync`;
export const PMS_CUSTOMER_PAYMENT_QUEUE_SYNC_ACKNOWLEDGEMENT = `${PMS_SECURED_API_URL}/payments/customers/queue-sync/acknowledgement`;
export const PMS_CUSTOMER_PAYMENT_SYNC_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/payments/customers/sync`;

export const PMS_GET_ALL_VENDOR_PAYMENT_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/payments/vendor`;
export const PMS_CANCEL_VENDOR_PAYMENT_BY_ID_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/payments/vendors/cancel`;
export const PMS_CREATE_VENDOR_PAYMENT_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/payments/vendors/create`;
export const PMS_VENDOR_PAYMENT_QUEUE_SYNC = `${PMS_SECURED_API_URL}/payments/vendors/queue-sync`;
export const PMS_VENDOR_PAYMENT_QUEUE_SYNC_ACKNOWLEDGEMENT = `${PMS_SECURED_API_URL}/payments/vendors/queue-sync/acknowledgement`;
export const PMS_VENDOR_PAYMENT_SYNC_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/payments/vendors/sync`;

// --------------------- pms Product ------------------------
export const PMS_GET_ALL_PRODUCT_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/products`;
export const PMS_GET_ALL_PUBLIC_PRODUCT = `${ROOT_URL}/public/products`;
export const PMS_GET_PRODUCT_BY_PHARMACY_ID_BY_ID = `${PMS_SECURED_API_URL}/products`;
export const PMS_PRODUCT_SYNC_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/products/sync`;
export const OS_SEARCH_PRODUCTS_URL = `${PUBLIC_API_URL}/products`;
export const PMS_GET_ALL_REQUIRED_PRODUCTS = `${PMS_SECURED_API_URL}/products/requistion`;
export const PMS_CREATE_PRODUCT = `${PMS_SECURED_API_URL}/products/create`;
export const PMS_UPDATE_PRODUCT = `${PMS_SECURED_API_URL}/products/update`;
export const DELETE_PMS_PRODUCT = `${PMS_SECURED_API_URL}/products/delete`;

export const GET_ALL_PMS_COMPANIES = `${PMS_SECURED_API_URL}/products/all/companies`;

export const GET_ALL_PMS_MEDICINE_TYPE = `${SECURED_API_URL}/categories/get-title/for-pharmacy`;

// --------------------- pms requisition-new-old ------------------------
export const PMS_CREATE_REQUISITION = `${PMS_SECURED_API_URL}/requisition/create`;
export const PMS_GET_ALL_REQUISITION_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/requisition`;
export const PMS_GET_REQUISITION_BY_PHARMACY_ID_BY_ID = `${PMS_SECURED_API_URL}/requisition/id`;
export const PMS_UPDATE_REQUISITION_BY_PHARMACY_ID_BY_ID = `${PMS_SECURED_API_URL}/requisition/update`;
export const PMS_CANCEL_REQUISITION_BY_PHARMACY_ID_BY_ID = `${PMS_SECURED_API_URL}/requisition/cancel`;
export const PMS_REMOVE_FROM_LESS_STOCK = `${PMS_SECURED_API_URL}/products/remove/from-less-stock`;

// --------------------- pms All Public Product By Company ------------------------
export const PMS_ALL_PRODUCT_BY_COMPANY_ID = `${ROOT_URL}/public/products/by`;

// --------------------- pms Report ------------------------
export const PMS_GET_REPORT_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/reports`;
export const PMS_GENERATE_EXCEL_REPORT_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/reports/daily-report/excel`;
export const PMS_GET_ACCOUNT_BALANCE_REPORT = `${PMS_SECURED_API_URL}/reports/account-balance`;
export const PMS_GET_EXPIRED_ITEM_REPORT = `${PMS_SECURED_API_URL}/reports/expiry-product/excel`;

// --------------------- pms Sale ------------------------
export const PMS_GET_ALL_SALE_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/sales`;
export const PMS_SALES_CASH_RECEIVED_BY_ID_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/sales/cash-received`;
export const PMS_SALES_NOT_CASH_RECEIVED_BY_ID_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/sales/not-cash-received`;
export const PMS_CASH_RECEIVED_REFRESH = `${PMS_SECURED_API_URL}/sales/cash-received-refresh`;

export const PMS_CREATE_SALES_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/sales/create`;
export const PMS_SALES_QUEUE_SYNC = `${PMS_SECURED_API_URL}/sales/queue-sync`;
export const PMS_SALES_QUEUE_SYNC_ACKNOWLEDGEMENT = `${PMS_SECURED_API_URL}/sales/queue-sync-acknowledgement`;
export const PMS_SALES_SYNC_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/sales/sync`;
export const PMS_UPDATE_SALES_BY_ID_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/sales/update`;
export const PMS_GET_SALE_BY_ID = `${PMS_SECURED_API_URL}/sales`;
export const PMS_GET_SALE_INVOICE = `${PMS_SECURED_API_URL}/reports/sale/invoice`;

// --------------------- pms Supplier ------------------------
export const PMS_GET_ALL_VENDOR_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/vendors`;
export const PMS_GET_VENDOR_BY_PHARMACY_ID_BY_ID = `${PMS_SECURED_API_URL}/vendors`;
export const PMS_GET_VENDOR_BY_PHARMACY_ID_BY_LOCAL_ID = `${PMS_SECURED_API_URL}/vendors/by/local-id`;
export const PMS_CREATE_VENDOR_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/vendors/create`;
export const PMS_DELETE_VENDOR_BY_ID = `${PMS_SECURED_API_URL}/vendors/delete`;
export const PMS_GET_ALL_LITE_VENDOR_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/vendors/lite`;
export const PMS_VENDOR_QUEUE_SYNC = `${PMS_SECURED_API_URL}/vendors/queue-sync`;
export const PMS_VENDOR_QUEUE_SYNC_ACKNOWLEDGEMENT = `${PMS_SECURED_API_URL}/vendors/queue-sync/acknowledgement`;
export const PMS_VENDOR_SYNC_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/vendors/sync`;
export const PMS_UPDATE_VENDOR_BY_ID_PHARMACY_ID = `${PMS_SECURED_API_URL}/vendors/update`;

//pharmacist // representative
export const PMS_GET_ALL_PHARMACIST = `${SECURED_API_URL}/pharmacy-owners/pharmacist/all`;
export const PMS_GET_PHARMACIST_BY_ID = `${SECURED_API_URL}/pharmacy-owners/pharmacist/id`;
export const PMS_CREATE_PHARMACIST = `${SECURED_API_URL}/pharmacy-owners/pharmacist/create`;
export const PMS_UPDATE_PHARMACIST = `${SECURED_API_URL}/pharmacy-owners/pharmacist/update`;
export const PMS_DELETE_PHARMACIST = `${SECURED_API_URL}/pharmacy-owners/pharmacist/delete`;

//roles
export const PMS_GET_ALL_ROLES = `${SECURED_API_URL}/pharmacy-owners/roles`;

// --------------------- pms Inventory Expired ------------------------
export const PMS_GET_EXPIRED_ITEMS = `${PMS_SECURED_API_URL}/inventory-entries/expired/by`;

// --------------------- pms Pharmacy Transaction Accounts ------------
export const PMS_GET_PHARMACY_TRANSACTION_ACCOUNTS = `${PMS_SECURED_API_URL}/pharmacy-users`;
export const PMS_CREATE_PHARMACY_TRANSACTION_ACCOUNT = `${PMS_SECURED_API_URL}/pharmacy-users/create`;
export const PMS_UPDATE_PHARMACY_TRANSACTION_ACCOUNT = `${PMS_SECURED_API_URL}/pharmacy-users/update`;

// --------------------- pms Transaction ------------
export const PMS_GET_PHARMACY_TRANSACTIONS = `${PMS_SECURED_API_URL}/pharmacy-user-transactions`;
export const PMS_GET_PHARMACY_TRANSACTION_BY_ID = `${PMS_SECURED_API_URL}/pharmacy-user-transactions`;
export const PMS_CREATE_PHARMACY_TRANSACTION = `${PMS_SECURED_API_URL}/pharmacy-user-transactions/create`;
export const PMS_CANCEL_PHARMACY_TRANSACTION = `${PMS_SECURED_API_URL}/pharmacy-user-transactions/cancel`;

// =================== reconciliation ==================
export const PMS_GET_ALL_RECONCILIATION = `${PMS_SECURED_API_URL}/reconciliations`;
export const PMS_CREATE_RECONCILIATION = `${PMS_SECURED_API_URL}/reconciliations/create`;
export const PMS_CANCEL_RECONCILIATION = `${PMS_SECURED_API_URL}/reconciliations/cancel`;

// =================== pms report==================
export const PMS_GENERATE_REQUISITION_PDF = `${PMS_SECURED_API_URL}/reports/requisition/pdf`;

// =================== offline config ==================
export const GET_PMS_OFFLINE_CONFIG_LIST = `${PMS_SECURED_API_URL}/offline-configs`;
export const GET_PMS_OFFLINE_CONFIG_BY_ID = `${PMS_SECURED_API_URL}/offline-configs/id`;
export const GENERATE_PMS_OFFLINE_CONFIG_TOKEN_BY_PHARMACY_ID = `${PMS_SECURED_API_URL}/offline-configs/generate-token`;
export const CREATE_PMS_OFFLINE_CONFIG = `${PMS_SECURED_API_URL}/offline-configs/create`;
export const UPDATE_PMS_OFFLINE_CONFIG = `${PMS_SECURED_API_URL}/offline-configs/update`;
// --------------------- pms End ---------------------------

/*--------------pms config--------*/
export const ADD_PRINTING_CONFIG = `${PMS_SECURED_API_URL}/pharmacy-configs/create-update`;
export const GET_PRINTING_CONFIG_BY_ID = `${PMS_SECURED_API_URL}/pharmacy-configs/pharmacy-id`;

/*--------------pharmacy report--------*/
export const GET_COMPANY_REPORT = `${PMS_SECURED_API_URL}/reports/company-reports`;
export const GET_PMS_DASHBOARD_TOP_DATA = `${PMS_SECURED_API_URL}/reports/fixed-count`;
export const GET_COMPANY_GRAPH_REPORT = `${PMS_SECURED_API_URL}/reports/daily-company-reports`;
export const GET_COMPANY_TOP_LESS_REPORT = `${PMS_SECURED_API_URL}/reports/top-less-product-details`;
export const GET_TOP_AND_LOWEST_PRODUCT_REPORT = `${PMS_SECURED_API_URL}/reports/top-low-selling-product-details`;

export const GENERATE_BARCODE_BY_ID = `${PMS_SECURED_API_URL}/products/generate/bar-code`;
export const GENERATE_BARCODES = `${PMS_SECURED_API_URL}/products/generate/bar-codes`;
export const POS_GET_PRODUCT_BY_BARCODE = `${PMS_SECURED_API_URL}/products/by/bar-code`;
export const POS_GET_PRODUCT_EXISTS_BY_BARCODE = `${PMS_SECURED_API_URL}/products/exists-by/bar-code`;
export const POS_GET_PRODUCT_EXISTS_BY_BARCODE_BY_PRODUCT_ID = `${PMS_SECURED_API_URL}/products/bar-code-exists-by/product-id`;

// Report
export const GET_ALL_JOURNAL_BY_SHOP_ID = `${PMS_SECURED_API_URL}/reports/journal-details`;

// Inventory key
export const INVENTORY_KEY_1 = "inventory";
export const INVENTORY_KEY_2 = "inventory2";
export const INVENTORY_KEY_3 = "inventory3";
export const INVENTORY_KEY_4 = "inventory4";
export const CUSTOMER_RETURN_INVENTORY = "customer-return";

// Inventory key
export const PURCHASE_PARK_KEY_1 = "purchase_park_1";
export const PURCHASE_PARK_KEY_2 = "purchase_park_2";
export const PURCHASE_PARK_KEY_3 = "purchase_park_3";
export const PURCHASE_PARK_KEY_4 = "purchase_park_4";
export const CUSTOMER_RETURN_PACK = "customer-return";

// Purchase title
export const PURCHASE_1_TITLE = "Default";
export const PURCHASE_2_TITLE = "Purchase by Invoice";
export const PURCHASE_3_TITLE = "3rd Party";
export const PURCHASE_4_TITLE = "Opening Stock";

// Barcode Key
export const BARCODE_SELECTED_PRODUCTS = "bar-code-selected-products";

// Purchase window
export const PURCHASE_DEFAULT_WINDOW = "DEFAULT";
export const PURCHASE_INVOICE_WINDOW = "INVOICE";
export const PURCHASE_THIRD_PARTY_WINDOW = "THIRD_PARTY";
export const PURCHASE_OPENING_STOCK_WINDOW = "OPENING_STOCK";

// Purchase Window Path
export const PURCHASE_WINDOW_PATH_MAP = {
    null: PMS_PURCHASE_PATH,
    [PURCHASE_DEFAULT_WINDOW]: PMS_PURCHASE_PATH,
    [PURCHASE_INVOICE_WINDOW]: PMS_PURCHASE2_PATH,
    [PURCHASE_THIRD_PARTY_WINDOW]: PMS_PURCHASE3_PATH,
    [PURCHASE_OPENING_STOCK_WINDOW]: PMS_PURCHASE4_PATH,
}

// Purchase key
export const PURCHASE_KEY_MAP = {
    [PURCHASE_DEFAULT_WINDOW]: INVENTORY_KEY_1,
    [PURCHASE_INVOICE_WINDOW]: INVENTORY_KEY_2,
    [PURCHASE_THIRD_PARTY_WINDOW]: INVENTORY_KEY_3,
    [PURCHASE_OPENING_STOCK_WINDOW]: INVENTORY_KEY_4,
}

// path key
export const PURCHASE_PATH_KEY = "purchase_path";
//color code
export const RED = "#dc3545";
export const GREEN = "#28a745";
export const YELLOW = "#ffc107";

export const ORDER_STATE = {
    "Order verification": "maroon",
    Pending: YELLOW,
    Picked: "indigo",
    Delivered: GREEN,
    Cancelled: RED,
    "Payment pending": YELLOW,
};

export const EIGHTY_MM_DISCOUNT = "EIGHTY_MM_DISCOUNT";
export const A4_DISCOUNT = "A4_DISCOUNT";
export const EIGHTY_MM_PAPER = "EIGHTY_MM";
export const FIFTY_EIGHT_MM_PAPER = "FIFTY_EIGHT_MM";
export const A4_PAPER = "A4";
export const BD_TK_SIGN = "৳";

export const PRODUCT_PERCENTAGE = 17.4;
export const SALE_PRICE__PERCENTAGE = 12;
