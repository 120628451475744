import {useLocation} from "react-router-dom";
import {CURRENCY} from "./Currency";
import moment from "moment";
import * as Permissions from "./Permissions";
import {
    GREEN,
    IMAGE_URL,
    PMS_GENERATE_REQUISITION_PDF,
    PMS_GET_SALE_INVOICE,
    PURCHASE_PATH_KEY,
    RED,
} from "./Constants";
import axios from "axios";
import React from "react";
import {Tag} from "antd";
import * as _ from "lodash";
import * as SLUG from "../routes/Slugs";

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const getParams = (query, data) => {
    for (const key of Object.keys(data)) {
        data = {...data, [key]: query.get(key) || ""};
    }
    return data;
};

export const isValueExistInSearch = (data) => {
    for (const key of Object.keys(data)) {
        if (data[key]) {
            return ["1"];
        }
    }
    return ["0"];
};

export const dateFormatForPrint = "DD-MM-YYYY hh:mm:ss a";
export const dateFormatForSend = "DD-MM-YYYY HH:mm:ss";
export const fromDate = (day = 2) =>
    moment(Date.now() - day * 24 * 3600 * 1000)
        .set({
            h: 0,
            m: 0,
            s: 0,
        })
        .format(dateFormatForSend);

export const toDate = () =>
    moment(Date.now())
        .set({
            h: 23,
            m: 59,
            s: 59,
        })
        .format(dateFormatForSend);

export const getMoment = (value) => moment(value, "DD-MM-YYYY HH:mm:ss").format(dateFormatForPrint);

export const getPharmacyId = () => {
    return JSON.parse(localStorage.getItem("selectedPharmacy")).id;
};

export const bindUrlWithParams = (url, params) => {
    let result = url;

    Object.keys(params).forEach((key) => {
        if (!params[key]) {
            delete params[key];
        }
    });

    Object.keys(params).forEach((key, index) => {
        if (index === 0) {
            result += `?${key}=${params[key]}`;
        } else {
            result += `&${key}=${params[key]}`;
        }
    });

    return result;
};

export const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const moneyFormatter = (value) => {
    if (!value) {
        return `${CURRENCY} ${formatter.format(0)}`;
    }

    return `${CURRENCY} ${formatter.format(value)}`;
};

export const moneyFormatterTwo = (value) => {
    if (!value) {
        return `${formatter.format(0)}`;
    }

    return `${formatter.format(value)}`;
};

export const getFullPreviousRoute = (history) => {
    return `${history.location.pathname}${history.location.search}`;
};

export const resetState = (data) => {
    for (const key of Object.keys(data)) {
        data = {...data, [key]: ""};
    }

    console.log("data - ", data);

    return data;
};

export const getAllNonObjectValues = (data) => {
    let singleValue = {};

    for (const [key, value] of Object.entries(data)) {
        if (typeof value !== "object") {
            singleValue[key] = value;
        }
    }
    return singleValue;
};

// export const getAllNestedObjectValues = (data) => {
//     let singleValue = [];
//
//     for (const [key, value] of Object.entries(data)) {
//         if (value && typeof value === "object") {
//             singleValue.push(value);
//         }
//     }
//     return singleValue;
// };

export const decryption = (encryptValue) => {
    let result = "";

    for (let i = 0; i < encryptValue.length; i++) {
        let decryptChar1 = String.fromCharCode(encryptValue.charCodeAt(i) ^ 6);
        let decryptChar2 =
            decryptChar1.charCodeAt(0) % 2 === 0
                ? String.fromCharCode(decryptChar1.charCodeAt(0) + 1)
                : String.fromCharCode(decryptChar1.charCodeAt(0) - 1);
        result += decryptChar2;
    }

    return result;
};

export const generateLocalId = (pharmacyId, userId) => {
    return `${pharmacyId}_${userId}_${new Date().getTime()}`;
};

export const hasPermission = (loggedInUserPermissions, permissions) => {
    if (permissions.includes(Permissions.ALL)) {
        return true;
    }

    for (const permission of permissions) {
        if (loggedInUserPermissions.includes(permission)) {
            return true;
        }
    }

    return false;
};

export const enumConverter = (value) => {
    if (!value) return;

    const words = value.toLowerCase().split("_");
    return words.map((value) => value[0].toUpperCase() + value.substring(1)).join(" ");
};

export const tableDateFormatter = (date) => {
    if (!date) return;
    return moment(date, "DD-MM-YYYY HH:mm:ss").format("D MMM YYYY hh:mm a");
};

export const pdfDateFormatter = (date) => {
    if (!date) return;
    return moment(date, "DD-MM-YYYY HH:mm:ss").format("DD/MMM/YYYY h:mm A");
};

export const generateLocalStorageKey = (name) => {
    const pharmacy = JSON.parse(localStorage.getItem("selectedPharmacy"));
    return `${name}_${pharmacy && pharmacy.pharmacyName}`;
};

export const calculateDiscount = (data) => {
    if (data.individualDiscount) {
        let discount = 0;

        data.saleDetailsList.forEach((v) => {
            let dis = v.discountPercent || data.discountPercent || v.autoDiscountPercent;
            dis = isNaN(dis) ? 0 : dis;

            discount += parseFloat(((v.salePrice * v.quantity * dis) / 100).toFixed(2));
        });

        return discount;
    }

    if (data.discountType === "FLAT") {
        return data.discountAmount;
    }

    return parseFloat(((data.totalPrice * data.discountPercent) / 100).toFixed(2));
};

export const requisitionPDFDownloadURL = (pharmacyId, requisitionId) => {
    return `${PMS_GENERATE_REQUISITION_PDF}/${pharmacyId}/${requisitionId}?x-token=${localStorage.getItem(
        "oushodsheba_access_token"
    )}`;
};

export const saleInvoicePDFDownloadURL = (pharmacyId, saleLocalId, isRetail) => {
    return `${PMS_GET_SALE_INVOICE}/${pharmacyId}/${saleLocalId}?x-token=${localStorage.getItem(
        "oushodsheba_access_token"
    )}&retail=${isRetail}`;
};

export const directPdfPrint = (pdfURL) => {
    axios({
        url: pdfURL,
        params: {},
        method: "GET",
        responseType: "blob",
    })
        .then((res) => {
            let iframe = document.getElementById("direct_pdf_download_frame");

            iframe.src = window.URL.createObjectURL(res.data);

            let iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

            if (iframeDoc.readyState === "complete") {
                setTimeout(() => {
                    iframe.contentWindow.focus();
                    iframe.contentWindow.print();
                }, 100);
            }
        })
        .catch((err) => alert(err));
};

export const getValidPhoneNumber = (phone) => {
    return phone && phone.startsWith("+88") ? phone.slice(4) : phone.slice(1);
};

export const getSaleOption = (product, isRetail, isRakNumber) => {
    return (
        <>
            <p style={{margin: "0", fontWeight: "bold"}}>
                {product.productName}
                {product.form ? ", " + product.form : ""}
                {product.strength ? ", " + product.strength : ""}
                {product.volume ? ", " + product.volume : ""}
                <span style={{marginRight: "5px", fontWeight: "normal"}}>
                    &nbsp;&nbsp;&nbsp;
                    {isRakNumber && product.rakNumber >= 0 ? "(Rak No. :  " + product.rakNumber + ")" : ""}
                </span>
            </p>
            <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                {product.companyName ? (
                    <p style={{margin: "0"}}>
                        <span
                            style={{
                                color:
                                    (isRetail
                                        ? product.quantity
                                        : (product.quantity / product.quantityInBox).toFixed(2)) <= 0
                                        ? RED
                                        : GREEN,
                                marginRight: "5px",
                            }}
                        >
                            QTY: {isRetail ? product.quantity : (product.quantity / product.quantityInBox).toFixed(2)},
                        </span>
                        <span style={{marginRight: "5px"}}>
                            {isRetail
                                ? `Cost/P: ${product.productPrice.toFixed(2)}, Sale/P: ${product.salePrice.toFixed(2)}`
                                : `Cost/B: ${product.boxPrice.toFixed(2)}, Sale/B: ${product.saleBoxPrice.toFixed(2)}`}
                            ,
                        </span>
                        {product.companyName}
                    </p>
                ) : (
                    <p style={{margin: "0", fontWeight: "bold"}}>No Company</p>
                )}
            </div>
        </>
    );
};

export const getPurchaseOption = (product, isRakNumber) => {
    return (
        <>
            <p style={{margin: "0", fontWeight: "bold"}}>
                {product.productName}
                {product.form ? ", " + product.form : ""}
                {product.strength ? ", " + product.strength : ""}
                {product.volume ? ", " + product.volume : ""}
                <span style={{marginRight: "5px", fontWeight: "normal"}}>
                    &nbsp;&nbsp;&nbsp;
                    {isRakNumber && product.rakNumber >= 0 ? "(Rak No. :  " + product.rakNumber + ")" : ""}
                </span>
            </p>
            <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                {product.companyName ? (
                    <p style={{margin: "0"}}>
                        <span
                            style={{
                                color: (product.quantity / product.quantityInBox).toFixed(2) <= 0 ? RED : GREEN,
                                marginRight: "5px",
                            }}
                        >
                            <Tag color={(product.quantity / product.quantityInBox).toFixed(2) <= 0 ? RED : GREEN}>
                                {product.quantity}
                            </Tag>
                            {(product.quantity / product.quantityInBox).toFixed(2)} box,
                        </span>
                        <span style={{marginRight: "5px"}}>{`Cost: ${product.boxPrice.toFixed(2)}`},</span>
                        {product.companyName}
                    </p>
                ) : (
                    <p style={{margin: "0", fontWeight: "bold"}}>No Company</p>
                )}
            </div>
        </>
    );
};

export const getInvoiceProductName = (product, fontSize) => {
    if (!product || !product.productName) return "Product name missing";

    return (
        <>
            <p style={{margin: "0px", fontSize: fontSize || "15px"}}>
                {product.productName}
                {product.form ? ", " + product.form : ""}
                {product.strength ? ", " + product.strength : ""}
                {product.volume ? ", " + product.volume : ""}
            </p>
        </>
    );
};

export const getDataFromNestedObject = (object, dataIndex) => {
    if (!dataIndex) return null;

    let split = dataIndex.split(".");
    let value = object;

    for (let i = 0; i < split.length; i++) {
        value = value[split[i]];
    }

    return value;
};

export const getBTPBox = (value, quantity, box) => {
    const _quantity = quantity ? quantity : 1;
    const _value = value ? value : 0;
    const _box = box ? box : 1;
    return _.round(_value * _quantity * _box, 2);
};

export const setPurchasePath = (path) => {
    localStorage.setItem(PURCHASE_PATH_KEY, path);
};

export const collapsedRoutes = new Set([
    SLUG.PMS_PURCHASE_PATH,
    SLUG.PMS_PURCHASE2_PATH,
    SLUG.PMS_PURCHASE3_PATH,
    SLUG.PMS_PURCHASE4_PATH,
    SLUG.PMS_SALE_PATH,
]);

export const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
};

export const paymentTypeColor = (value) => {
    if (value) {
        if (value.toLowerCase().includes("bkash")) {
            return "#DC0F6A";
        } else if (value.toLowerCase().includes("card")) {
            return "blue";
        }
    }
};

export const cleanUpScannedValue = (scannedValue) => {
    if (scannedValue.includes("Control")) {
        return (scannedValue = "");
    }

    return scannedValue
        .replace("Shift", "")
        .replace("Enter", "")
        .replace(/ArrowUp+/g, "")
        .replace(/Backspace+/g, "")
        .replace(/CapsLock+/g, "")
        .replace(/Ctrl+/g, "")
        .replace(/Tab+/g, "")
        .replace(/Control+/g, "")
        .replace(/ArrowRight+/g, "")
        .replace(/ArrowLeft+/g, "")
        .replace(/[^a-zA-Z0-9]/g, "")
        .replace(!/^[a-zA-Z0-9]+$/.test(scannedValue), "")
        .replace(/ArrowDown+/g, "");
};

export const getPaymentType = (value) => {
    if (value.toLowerCase() === "bkash") {
        return "MFS";
    }
    if (value.toLowerCase() === "bkash payment") {
        return "MFS Payment";
    }

    return value;
};

export const getFractionValidate = (value) => {
    if (value == null || value === "" || value === undefined || value > 0) {
        // Check if the value is a whole number
        if (Number.isInteger(value)) {
            return Promise.resolve();
        } else {
            return Promise.reject("Total qty can't be a fraction");
        }
    }
    return Promise.reject("Please enter a positive number");
};

export const getImagePath = (value) => {
    if (value) {
        return `${IMAGE_URL}${value}?dummy=${new Date().getMilliseconds()}`;
    }
    return "";
};

export const getDuplicateProductBarcode = (product, data) => {
    return data
        ?.map((item) => {
            const _product = {
                productName: product.productName,
                strength: product.strength ? product.strength : "",
                volume: product.volume ? product.volume : "",
                form: product.form,
            };

            const _product1 = {
                productName: item.product.productName,
                strength: item.product.strength ? item.product.strength : "",
                volume: item.product.volume ? item.product.volume : "",
                form: item.product.form,
            };

            if (JSON.stringify(_product).toUpperCase() === JSON.stringify(_product1).toUpperCase()) {
                return (
                    "Product Exist by " +
                    _product.productName +
                    " " +
                    _product.strength +
                    " " +
                    _product.volume +
                    " " +
                    _product.form
                );
            } else if (product.barCodeValue && product.barCodeValue === item.barCodeValue) {
                return "Product exist by barcode " + product.barCodeValue;
            } else {
                return null;
            }
        })
        .filter((value) => value !== null);
};

export const getDuplicateBarcode = (product, data) => {
    return data?.map((item) => {
        // checking barcode not change
        if ((product?.product?.id === item?.product?.id) && (product?.barCodeValue === item?.barCodeValue)) return null
        else if (product?.barCodeValue && product?.barCodeValue === item?.barCodeValue) {
            return "Product exist by barcode " + product.barCodeValue;
        } else {
            return null;
        }
    })
        .filter((value) => value !== null);
};
