import axios from "axios";
import * as URL from "../../helpers/Constants";
import {getPharmacyId} from "../../helpers/Utils";

export default class PMSProductServices {
  static getAllProductsByPharmacyId(id, params) {
    if (params.inventoryCreated === undefined || params.inventoryCreated === null) {
      delete params.inventoryCreated;
    }

    return axios.get(`${URL.PMS_GET_ALL_PRODUCT_BY_PHARMACY_ID}/${id}`, {
      ...PMSProductServices.getAuthHeader(),
      params,
      paramsSerializer: (params) => {
        let result = "";
        Object.keys(params).forEach((key) => {
          result += `${key}=${encodeURIComponent(params[key])}&`;
        });
        return result.substr(0, result.length - 1);
      },
    });
  }

  static getAllRequiredProductsByPharmacyId() {
    return axios.get(`${URL.PMS_GET_ALL_REQUIRED_PRODUCTS}/${getPharmacyId()}`, {
      ...PMSProductServices.getAuthHeader(),
    });
  }

  static getAllPublicProducts(page, size, id, categorySlug, tagTitle) {
    return axios.get(`${URL.PMS_GET_ALL_PUBLIC_PRODUCT}/`, {
      ...PMSProductServices.getAuthHeader(),
      params: { page, size, categorySlug, tagTitle },
    });
  }

  static getProductByPharmacyIdById(pharmacyId, id) {
    return axios.get(
      `${URL.PMS_GET_PRODUCT_BY_PHARMACY_ID_BY_ID}/${pharmacyId}/${id}`,
      PMSProductServices.getAuthHeader()
    );
  }

  static getAllProductsFromOS(params) {
    return axios.get(`${URL.OS_SEARCH_PRODUCTS_URL}`, {
      ...PMSProductServices.getAuthHeader(),
      params,
    });
  }

  static getAllProductByCompanyId(params) {
    return axios.get(`${URL.PMS_ALL_PRODUCT_BY_COMPANY_ID}`, {
      ...PMSProductServices.getAuthHeader(),
      params,
    });
  }

  static getAllPMSCompany(id, params) {
    return axios.get(`${URL.GET_ALL_PMS_COMPANIES}/${id}`, {
      ...PMSProductServices.getAuthHeader(),
      params,
    });
  }

  static addProduct(pharmacyId, data) {
    return axios.post(`${URL.PMS_CREATE_PRODUCT}/${pharmacyId}`, data, PMSProductServices.getAuthHeader());
  }

  static updateProduct(pharmacyId, id, data) {
    return axios.put(`${URL.PMS_UPDATE_PRODUCT}/${pharmacyId}/${id}`, data, PMSProductServices.getAuthHeader());
  }

  static deletePMSProduct(pharmacyId, id) {
    return axios.delete(`${URL.DELETE_PMS_PRODUCT}/${pharmacyId}/${id}`, PMSProductServices.getAuthHeader());
  }

  static generateBarcodeById(productId, pharmacyId) {
    return axios({
      url: `${URL.GENERATE_BARCODE_BY_ID}/${productId}/${pharmacyId}`,
      method: "GET",
      responseType: "blob",
      ...PMSProductServices.getAuthHeader(),
    });
  }

  static generateBarcodesById = (pharmacyId, params) => {
    return axios.get(`${URL.GENERATE_BARCODES}/${pharmacyId}`, {
      params,
      responseType: "blob",
      paramsSerializer: {
        indexes: null, // by default: false
      },
      ...PMSProductServices.getAuthHeader(),
    });
  };

  static getProductByBarcode(barcode, shopId) {
    return axios.get(`${URL.POS_GET_PRODUCT_BY_BARCODE}/${barcode}/${shopId}`, {
      ...PMSProductServices.getAuthHeader(),
    });
  }

  static getProductExistsByBarcode(barcode, shopId) {
    return axios.get(`${URL.POS_GET_PRODUCT_EXISTS_BY_BARCODE}/${barcode}/${shopId}`, {
      ...PMSProductServices.getAuthHeader(),
    });
  }

  static getProductExistsByProductIdAndBarCode(barCode, shopId, productId) {
    return axios.get(
      `${URL.POS_GET_PRODUCT_EXISTS_BY_BARCODE_BY_PRODUCT_ID}/${shopId}/${productId}`,
      // {
      //   params: {
      //     barCode,
      //   },
      // },
      {
        params: { barCode },
        ...PMSProductServices.getAuthHeader(),
      }
    );
  }

  static getAuthHeader() {
    const accessToken = localStorage.getItem("oushodsheba_access_token");
    return { headers: { Authorization: `Bearer ${accessToken}` } };
  }
}
